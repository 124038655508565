import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { networkService } from "../../services";
import dateService from "../../services/date.service";
import websocketLoggerService from "../../services/websocket-logger";
import { useConsumerStore } from "../../store";
import { useFormStore } from "../../store/form";
import { cdtMapping } from "../shared/config";

const Success = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const { referralCode } = useFormStore();

  const { user } = useAuth0();

  const handleDone = () => {
    websocketLoggerService.sendMessage({
      authenticationId: user?.sub,
      externalUserId: null,
      eventType: `User signed out`,
      eventSubType: `User signed out via Done button on /success`,
      eventData: `navigating to /`,
    });
    navigate("/logout");
  };

  const consumer = useConsumerStore((state) => state.consumer);

  React.useEffect(() => {
    if (!state) return;

    const sheetIdFromReferralCode =
      cdtMapping.referralcodes[referralCode]?.excelsheetid;

    const organizationId = cdtMapping.orgid;
    const valuesToLog = state.valuesToLog;

    if (!state.isRegistered) {
      // Updated column to be phone number column
      networkService
        .post(`/api/sheets-integration`, {
          organizationId,
          spreadsheetId: sheetIdFromReferralCode,
          sheetName: "Sheet1",
          primaryKeyColumnIndex: 9,
          endingColumn: "AN",
          dataToStore: [valuesToLog],
        })
        .then((res) => {
          websocketLoggerService.sendMessage({
            externalUserId: consumer.id,
            authenticationId: user?.sub,
            eventType: `Excel sheet integration`,
            eventSubType: "web intake end",
            eventData: "Excel sheet integration request successful",
          });
        })
        .catch((err) => {
          websocketLoggerService.sendMessage({
            externalUserId: consumer.id,
            authenticationId: user?.sub,
            eventType: `Excel sheet integration`,
            eventSubType: "web intake end",
            eventData: "Excel sheet integration request failed",
          });
          console.log(err);
        });
    }

    localStorage.clear();
  }, [state, user]);

  return (
    <div
      style={{
        backgroundImage: `url(32.png)`,
      }}
      className="flex items-center min-h-screen flex-col p-10 px-20 gap-4 max-lg:px-2 pt-8 bg-no-repeat bg-cover bg-center">
      <div className="max-w-2xl border-t-8 rounded border-[#3E40F9] p-10 mx-auto text-center bg-white shadow-2xl">
        <img src={logo} alt="logo" className="w-52 py-5 mx-auto" />
        <span className="max-w-lg text-3xl text-center max-lg:w-full">
          Congratulations you are enrolled{" "}
          {sessionStorage.getItem("program") ||
            "Digital Diabetes Management Plan"}
        </span>

        <div className="p-5 bg-[#F6F6FC] mt-5">
          <div>Download our app</div>
          <div className="flex justify-center items-center gap-5">
            <div className="flex mt-3 w-48 h-14 bg-black text-white rounded-lg items-center justify-center">
              <div className="mr-3">
                <svg viewBox="30 336.7 120.9 129.2" width="30">
                  <path
                    fill="#FFD400"
                    d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z"
                  />
                  <path
                    fill="#FF3333"
                    d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z"
                  />
                  <path
                    fill="#48FF48"
                    d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z"
                  />
                  <path
                    fill="#3BCCFF"
                    d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z"
                  />
                </svg>
              </div>
              <div>
                <div className="text-xs">GET IT ON</div>
                <div className="text-xl font-semibold font-sans -mt-1">
                  Google Play
                </div>
              </div>
            </div>
            <div className="flex mt-3 w-48 h-14 bg-black text-white rounded-xl items-center justify-center">
              <div className="mr-3">
                <svg viewBox="0 0 384 512" width="30">
                  <path
                    fill="currentColor"
                    d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
                  />
                </svg>
              </div>
              <div>
                <div className="text-xs">Download on the</div>
                <div className="text-2xl font-semibold font-sans -mt-1">
                  App Store
                </div>
              </div>
            </div>
          </div>
        </div>

        <ul className="border-[#424BF9] border p-4 rounded mt-10 text-left">
          <div className=" font-bold border-b py-2 pb-4 border-[#424BF9]">
            Join scheduled introductory meeting
          </div>
          <div className="mt-5 flex justify-between">
            <div>
              <div className="text-sm ">Appointment</div>
              <div className="font-bold text-[#424BF9]">
                {/* Friday, 15 Sep 10:00 - 10:30 AM */}
                {dateService
                  .getMomentObj(state.startDateTime)
                  .format("dddd, MMMM Do YYYY")}{" "}
                {", "}
                {dateService
                  .getMomentObj(state.startDateTime)
                  .format("h:mm A")}{" "}
                - {dateService.getMomentObj(state.endDateTime).format("h:mm A")}
              </div>
            </div>
            <div className="pr-10">
              <div className="text-sm ">Location</div>
              <div className="font-bold text-[#424BF9]">Online</div>
            </div>
          </div>
        </ul>
        <button
          onClick={handleDone}
          className="self-center px-12 py-4 w-full mt-4 bg-[#F6F6FC] rounded font-bold">
          Add to Calendar
        </button>

        <p className="mt-4 max-w-md mx-auto text-[#3E40F9]">
          You will also receive confirmation over email and text. We look
          forward to working with you.
        </p>
      </div>
      {/* <button
        onClick={handleDone}
        className="w-max self-center px-12 py-4 mt-1 text-[#222424] rounded font-bold">
        Done
      </button> */}
    </div>
  );
};

export default Success;
