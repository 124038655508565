import ArrowRightIcon from "../../shared/icons/arrow-right-icon";
import ArticleCard from "./article-card";
import AssessmentCard from "./assessment-card";
import DoctorCard from "./doctor-card.coomponent";

const data = [
  {
    image:
      "https://plus.unsplash.com/premium_photo-1658506671316-0b293df7c72b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    specialty: "General Physician",
    name: "DR. MICHELLE MONAGHAN",
    link: "#",
  },
  {
    image:
      "https://images.unsplash.com/photo-1597764690472-ec054f1c8637?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    specialty: "Cardiologist",
    name: "DR. JOHN DOE",
    link: "#",
  },
  {
    image:
      "https://images.unsplash.com/photo-1582750433449-648ed127bb54?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    specialty: "Pediatrician",
    name: "DR. JANE SMITH",
    link: "#",
  },
];

const articles = [
  {
    image:
      "https://images.unsplash.com/photo-1476480862126-209bfaa8edc8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    category: "Mental Health",
    title: "Walking in nature can improve your mental health",
  },
  {
    image:
      "https://images.unsplash.com/photo-1494597564530-871f2b93ac55?q=80&w=2013&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    category: "Fitness",
    title: "5 Simple Exercises to Improve Your Posture",
  },
  {
    image:
      "https://plus.unsplash.com/premium_photo-1665673312770-90df9f77ddfa?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    category: "Nutrition",
    title: "The Best Foods for a Good Night's Sleep",
  },
  {
    image:
      "https://images.unsplash.com/34/rcaNUh3pQ9GD8w7Iy8qE__DSC0940.jpg?q=80&w=2076&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    category: "Nutrition",
    title: "How to Boost Your Immune System Naturally in 7 Days",
  },
  {
    image:
      "https://images.unsplash.com/photo-1519996529931-28324d5a630e?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    category: "Nutrition",
    title: "Can Your Diet Really Impact Your Mental Health?",
  },
  {
    image:
      "https://images.unsplash.com/photo-1470167290877-7d5d3446de4c?q=80&w=1976&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    category: "Nutrition",
    title: "The Role of Probiotics in Digestive Health: Myths vs. Facts",
  },
];

const HomeContent = () => {
  return (
    <div className="p-10">
      <h1 className="text-2xl">Home</h1>
      <div>
        <div className="flex justify-between mt-6 w-full">
          <div className="flex-1">Your Appointments</div>
          <div className="w-fit">
            <button className="flex items-center justify-end gap-2">
              <div className="text-sm flex items-center gap-2">
                <div>View History</div>
                <div>
                  <ArrowRightIcon />
                </div>
              </div>
            </button>
          </div>
        </div>
        <div className="flex gap-4 mt-5">
          {data.map((doctor, index) => (
            <DoctorCard
              key={index}
              image={doctor.image}
              specialty={doctor.specialty}
              name={doctor.name}
              link={doctor.link}
            />
          ))}
        </div>
      </div>
      <div className="mt-10">
        <div className="flex justify-between mt-6 w-full">
          <div className="flex-1">2 New Assessment</div>
          <div className="w-fit">
            <button className="flex items-center justify-end gap-2">
              <div className="text-sm flex items-center gap-2">
                <div>View All</div>
                <div>
                  <ArrowRightIcon />
                </div>
              </div>
            </button>
          </div>
        </div>
        <div className="grid-cols-3 grid pt-3 gap-4">
          <AssessmentCard
            title="Diabetes Assessment"
            description="Complete this assessment to know more about your Diabetes condition"
            link="/assessment"
          />
          <AssessmentCard
            title="Diabetes program enrolment"
            description="Complete this assessment to find and enroll in the program that best meets your needs"
            link="/booking-flow"
          />
          <AssessmentCard
            title="Consent Forms"
            description="Consents for your upcoming appointment"
            link="https://demo-consents.azo.dev/consent/patient-onboarding-2"
            isConsent={true}
          />
          <div></div>
        </div>
      </div>
      <div className="mt-10">
        <div className="flex justify-between mt-6 w-full">
          <div className="flex-1">Featured Contents</div>
          <div className="w-fit">
            <button className="flex items-center justify-end gap-2">
              <div className="text-sm flex items-center gap-2">
                <div>View All</div>
                <div>
                  <ArrowRightIcon />
                </div>
              </div>
            </button>
          </div>
        </div>
        <div className="grid gap-8 grid-cols-1 sm:grid-cols-3 mt-2">
          {articles.map((article, index) => (
            <ArticleCard
              key={index}
              image={article.image}
              category={article.category}
              title={article.title}
            />
          ))}
        </div>
      </div>
      <div className="mt-10">
        <div className="flex justify-between mt-6 w-full">
          <div className="flex-1">Quick Links</div>
        </div>
        <div className="mt-2 flex flex-col gap-3">
          <div className="flex items-center justify-between border border-primary-700/40 p-4 rounded-md">
            <div className="font-semibold">Manage Health Documents</div>
            <ArrowRightIcon />
          </div>
          <div className="flex items-center justify-between border border-primary-700/40  p-4 rounded-md">
            <div className="font-semibold">Explore Health Articles</div>
            <ArrowRightIcon />
          </div>
          <div className="flex items-center justify-between border border-primary-700/40  p-4 rounded-md">
            <div className="font-semibold">Find a Doctor</div>
            <ArrowRightIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeContent;
