import { useEffect, useState } from "react";
import ScheduleSession from "../schedule-session/schedule-session.component";
import PatientInfoForm from "../user-info/user-info-form.component";
import Steps from "./insurance-flow-steps.component";

const InsuranceFlow = () => {
  const [step, setStep] = useState(parseInt(localStorage.getItem("step")) || 0);

  useEffect(() => {
    localStorage.setItem("step", step.toString());
  }, [step]);

  const [userInfoLog, setUserInfoLog] = useState([]);

  const [isRegistered, setIsRegistered] = useState(false);

  const StepContent = [
    <PatientInfoForm
      key={1}
      setStep={setStep}
      setUserInfoLog={setUserInfoLog}
      isRegistered={setIsRegistered}
    />,
    <ScheduleSession
      key={2}
      setStep={setStep}
      userInfoLog={userInfoLog}
      isRegistered={isRegistered}
    />,
  ];

  return (
    <div className="flex max-lg:flex-col max-lg:px-0 max-lg:gap-0">
      <Steps step={step} setStep={setStep} />
      {StepContent[step]}
    </div>
  );
};

export default InsuranceFlow;
