import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import ProfileIcon from "../../../assets/images/profile.png";
import SidebarLink from "../../shared/components/sidebar-link.component";
import { sidebarLinks } from "../../shared/config";

function Sidebar() {
  const intl = useIntl();
  const navigate = useNavigate();

  const handleNavLinkClick = (to: string) => {
    navigate(to);
  };

  return (
    <>
      <div className={` font-inter     text-gray-800 lg:flex  `}>
        <div
          className={`fixed inset-y-0 left-0 z-30 flex h-screen flex-col   bg-white  lg:relative lg:inset-0 lg:translate-x-0 
          ${"w-64 -translate-x-full ease-in"}
          
          `}>
          <div className={`flex items-center justify-between pb-6 pt-4   `}>
            <div
              className={`flex items-center justify-between ${"px-4"}
           `}>
              <a href="/" target="_blank" rel="noreferrer">
                <img className="py-2 w-32" src={logo} alt="logo" />
              </a>
            </div>
          </div>

          <nav className="h-[calc[100vh-20rem]] flex-1 space-y-2   px-2 overflow-y-scroll overflow-x-hidden">
            {sidebarLinks.map((link, index) => (
              <SidebarLink
                key={index}
                to={link.to}
                messageId={link.messageId}
                Icon={link.Icon}
                activePath={link.activePath}
                handleNavLinkClick={handleNavLinkClick}
                name={link.name}
              />
            ))}
          </nav>

          <hr className="border-gradient mb-4 mt-1 border-t-1 border-grey" />

          <div className={` flex flex-col  ${"pl-3 pr-2 "}`}>
            <div className="flex items-center gap-2 pb-2">
              <button
                onClick={() =>
                  handleNavLinkClick(`/my-profile/asdasd/profile-tab`)
                }
                className="flex  items-center  text-lg font-bold text-gray-800 hover:text-gray-700 hover:underline">
                <img
                  className={`rounded-full  object-cover ${"h-10 w-10"}`}
                  src={ProfileIcon}
                  alt="profile"
                />
              </button>

              <div>
                <button
                  onClick={() =>
                    handleNavLinkClick(`/my-profile/asdasdasd/profile-tab`)
                  }
                  className="flex items-center space-x-3 pb-1 text-base font-semibold text-gray-800 hover:text-gray-700 hover:underline">
                  <span data-clarity-mask="True">Dwayne Jhonson</span>
                </button>
              </div>
            </div>

            <button
              onClick={() =>
                handleNavLinkClick(`/my-profile/asdasd/profile-tab`)
              }
              className="py-2 text-left text-lg tracking-wider text-gray-500">
              <FormattedMessage
                id="sidebar.my_profile"
                defaultMessage="Settings"
              />
            </button>

            <button
              onClick={() => handleNavLinkClick("/logout")}
              className="flex items-center space-x-3 py-2 text-lg tracking-wider text-gray-500">
              <span>
                <FormattedMessage id="sidebar.logout" defaultMessage="Logout" />
              </span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
