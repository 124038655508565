import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import HeroImg from "../../assets/images/hero-img.png";
import logo from "../../assets/images/logo.png";
import websocketLoggerService from "../../services/websocket-logger";
import { useFormStore } from "../../store/form";
import { cdtMapping } from "../shared/config";

const WelcomeScreen: React.FC = () => {
  const [userReferralCode, setUserReferralCode] = useState<string>("nemg");
  const [isReferralCodeValid, setIsReferralCodeValid] =
    useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [isExpired, setIsExpired] = useState<boolean>(false);

  const { user, logout } = useAuth0();

  const { setReferralCode } = useFormStore();

  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!user) return;
  //   logout();
  //   sessionStorage.clear();
  //   localStorage.clear();
  // }, [user]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log(e.target.value);

    for (const key in cdtMapping.referralcodes) {
      if (
        cdtMapping.referralcodes[key].name === userReferralCode.toUpperCase()
      ) {
        if (cdtMapping.referralcodes[key].isExpired) {
          setIsExpired(true);
          websocketLoggerService.sendMessage({
            externalUserId: null,
            authenticationId: null,
            eventType: "Enter referral code",
            eventSubType: "Check referral code",
            eventData: "Referral code is expired",
          });
        } else {
          setIsReferralCodeValid(true);
          setReferralCode(userReferralCode.toUpperCase());
          websocketLoggerService.sendMessage({
            externalUserId: null,
            authenticationId: null,
            eventType: "Enter referral code",
            eventSubType: "Check referral code",
            eventData: "Referral code is valid, navigate to /booking-flow",
          });
          navigate("/program");
        }

        break;
      } else {
        setIsReferralCodeValid(false);
        websocketLoggerService.sendMessage({
          externalUserId: null,
          authenticationId: null,
          eventType: "Enter referral code",
          eventSubType: "check referral code",
          eventData: "Referral code is invalid",
        });

        setError(true);
      }
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url(32.png)`,
      }}
      className=" bg-no-repeat bg-cover bg-center flex px-16 gap-12 min-h-screen  py-8 max-lg:px-4 ">
      <div className="flex-1 flex gap-6 flex-col mt-8 max-lg:pb-10">
        <a href="/" target="_blank" rel="noreferrer">
          <img src={logo} alt="logo" className="w-52" />
        </a>
        <div className="">
          <div className="mt-2">
            <h1 className="text-5xl leading-tight">
              Empower Health, Control Diabetes: Your Path to Better Living
            </h1>
            <div className="mt-4 pr-8 text-lg max-lg:w-full">
              Thank you for your interest in the programme. In this form we'll
              ask you a few questions — we want to ensure we tailor a unique
              experience for you when we first meet!
            </div>
            <div className="mt-8 flex flex-col gap-3 mb-5">
              <div className="flex items-center text-[#314DD3] justify-start border border-[#C7D2FC] gap-5 py-3 px-4 bg-white rounded-md border-l-4 border-l-[#222424]">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_5371_5383)">
                    <path
                      d="M9.99681 15.172L19.1888 5.979L20.6038 7.393L9.99681 18L3.63281 11.636L5.04681 10.222L9.99681 15.172Z"
                      fill="#09121F"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5371_5383">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p>Diabetes specialist and community support</p>
              </div>
              <div className="flex items-center text-[#314DD3] justify-start border border-[#C7D2FC] gap-5 py-3 px-4 bg-white rounded-md border-l-4 border-l-[#222424]">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_5371_5383)">
                    <path
                      d="M9.99681 15.172L19.1888 5.979L20.6038 7.393L9.99681 18L3.63281 11.636L5.04681 10.222L9.99681 15.172Z"
                      fill="#09121F"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5371_5383">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p>Personalized goals for managing Diabetes</p>
              </div>
              <div className="flex items-center text-[#314DD3] justify-start border border-[#C7D2FC] gap-5 py-3 px-4 bg-white rounded-md border-l-4 border-l-[#222424]">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_5371_5383)">
                    <path
                      d="M9.99681 15.172L19.1888 5.979L20.6038 7.393L9.99681 18L3.63281 11.636L5.04681 10.222L9.99681 15.172Z"
                      fill="#09121F"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_5371_5383">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p>Library of Diabetes resources</p>
              </div>
            </div>
            <div className="flex gap-2  mt-4">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_95_2445)">
                  <path
                    d="M6.382 5.968C7.97575 4.69107 9.95781 3.99674 12 4C14.125 4 16.078 4.736 17.618 5.968L19.071 4.515L20.485 5.929L19.032 7.382C20.0897 8.70615 20.7521 10.302 20.9428 11.986C21.1336 13.6699 20.845 15.3735 20.1103 16.9007C19.3756 18.4279 18.2246 19.7167 16.7898 20.6186C15.355 21.5205 13.6947 21.999 12 21.999C10.3053 21.999 8.64498 21.5205 7.2102 20.6186C5.77541 19.7167 4.62443 18.4279 3.88973 16.9007C3.15502 15.3735 2.86644 13.6699 3.0572 11.986C3.24796 10.302 3.9103 8.70615 4.96801 7.382L3.51501 5.93L4.92901 4.516L6.382 5.969V5.968ZM12 20C12.9193 20 13.8295 19.8189 14.6788 19.4672C15.5281 19.1154 16.2997 18.5998 16.9498 17.9497C17.5998 17.2997 18.1154 16.5281 18.4672 15.6788C18.8189 14.8295 19 13.9193 19 13C19 12.0807 18.8189 11.1705 18.4672 10.3212C18.1154 9.47194 17.5998 8.70026 16.9498 8.05025C16.2997 7.40024 15.5281 6.88463 14.6788 6.53284C13.8295 6.18106 12.9193 6 12 6C10.1435 6 8.36301 6.7375 7.05026 8.05025C5.7375 9.36301 5.00001 11.1435 5.00001 13C5.00001 14.8565 5.7375 16.637 7.05026 17.9497C8.36301 19.2625 10.1435 20 12 20ZM13 12H16L11 18.5V14H8.00001L13 7.495V12ZM8.00001 1H16V3H8.00001V1Z"
                    fill="#222424"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_95_2445">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <div className="text-[#222424]">It will only take 3 mins</div>
            </div>
          </div>

          <form className="mt-5" onSubmit={handleFormSubmit}>
            <div className="flex flex-col">
              <div className="relative flex items-center  gap-2 max-lg:flex-col">
                <input
                  type="text"
                  required
                  value="nemg"
                  onChange={(e) => {
                    setUserReferralCode(e.target.value);
                    setError(false);
                    setIsExpired(false);
                  }}
                  className={`hidden 
                  ${
                    isReferralCodeValid &&
                    userReferralCode.length > 0 &&
                    "border-green-500"
                  }
                  ${isExpired && "border-red-500"}
                  ${error && "border-red-500"}
                  `}
                />
                {isReferralCodeValid ? (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="absolute right-36 top-4 max-lg:right-4"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.2812 23.7188C5.81958 23.7188 0.5625 18.4617 0.5625 12C0.5625 5.53833 5.81958 0.28125 12.2812 0.28125C18.7429 0.28125 24 5.53833 24 12C24 18.4617 18.7429 23.7188 12.2812 23.7188ZM12.2812 1.84375C6.68125 1.84375 2.125 6.4 2.125 12C2.125 17.6 6.68125 22.1562 12.2812 22.1562C17.8813 22.1562 22.4375 17.6 22.4375 12C22.4375 6.4 17.8813 1.84375 12.2812 1.84375ZM11.2711 15.6773L18.3023 8.64604C18.6078 8.34054 18.6078 7.84681 18.3023 7.5414C17.9968 7.2359 17.5031 7.2359 17.1977 7.5414L10.7187 14.0204L8.14602 11.4476C7.84052 11.1421 7.34679 11.1421 7.04138 11.4476C6.73588 11.7531 6.73588 12.2469 7.04138 12.5523L10.1664 15.6773C10.3187 15.8296 10.5188 15.9062 10.7187 15.9062C10.9187 15.9062 11.1187 15.8296 11.271 15.6773H11.2711Z"
                      fill="#00AE74"
                    />
                  </svg>
                ) : null}

                {error ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#EF0035"
                    strokeWidth="2"
                    strokeLinecap="round"
                    className="absolute right-36 top-3 max-lg:right-4"
                    strokeLinejoin="round">
                    <path d="M15 15L9 9m6 0l-6 6" />
                    <circle cx="12" cy="12" r="10" />
                  </svg>
                ) : null}

                <button
                  type="submit"
                  className=" bg-[#222424] w-60 py-3 text-white rounded font-bold max-lg:w-full">
                  Let's Start
                </button>
              </div>
              {
                <div className="text-red-500 text-sm mt-2">
                  {error && "Please enter a valid referral code"}
                </div>
              }
              {
                <div className="bg-primary-50 rounded mt-2">
                  {isExpired && (
                    <div className="p-4 px-3">
                      <p>
                        Thank you for your interest! Due to overwhelming
                        response, we have reached maximum capacity for this
                        round.
                      </p>
                      <p className="mt-1">
                        Please click{" "}
                        <a
                          className="text-primary-900"
                          href="https://coq0zf97cpd.typeform.com/to/p9i4Q6Kd"
                          target="_blank"
                          rel="noopener noreferrer">
                          here
                        </a>{" "}
                        to register your interest. We will reach out when we
                        commence the next recruitment exercise
                      </p>
                    </div>
                  )}
                </div>
              }
            </div>
          </form>
        </div>
      </div>
      <div className="flex-1 max-lg:hidden ">
        <div className="w-full h-full rounded-3xl max-lg:w-full max-lg:p-0 max-lg:mt-10 max-lg:rounded-none">
          <div className="w-full h-full rounded-3xl relative carousel overflow-hidden max-lg:rounded-none">
            <div className="carousel-images flex h-full max-lg:mt-32 lg:h-3/4">
              <img
                src={HeroImg}
                alt="Image 1"
                className="h-full rounded-3xl max-lg:rounded-none object-cover lg:fixed lg:h-[90%] w-100 lg:w-[45%]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeScreen;
