import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";

interface Responses {
  question1?: string;
  question2?: string;
  question3?: string;
  question4?: string;
  question5?: string;
  question6?: string;
  question7?: string;
  question8?: string;
  question9?: string;
  question10?: string;
  Symptoms?: string[];
}

// Program questions

interface ProgramquestionsType {
  id: string;
  text: string;
  options: Array<{
    label: string;
    value: string;
  }>;
  multipleSelection: boolean;
  mandatory: boolean;
}

interface questionnaireProps {
  question: ProgramquestionsType;
  response: string | undefined | string[];
  onChange: (id: string, value: string | string[]) => void;
  stepState: [
    step: number,
    setStep: React.Dispatch<React.SetStateAction<number>>
  ];
  handleSubmit: () => void;
}

const Programquestions: ProgramquestionsType[] = [
  {
    id: "question1",
    text: "What is your diabetes diagnosis?",
    options: [
      { label: "Type 1", value: "type1" },
      { label: "Type 2", value: "type2" },
      { label: "Gestational (during pregnancy)", value: "gestational" },
      { label: "Prediabetes", value: "prediabetes" },
      { label: "No diagnosis", value: "no_diagnosis" },
    ],
    multipleSelection: false,
    mandatory: true,
  },
  {
    id: "question2",
    text: "How long have you been living with diabetes?",
    options: [
      { label: "Less than 1 year", value: "less_than_1_year" },
      { label: "1–5 years", value: "1_5_years" },
      { label: "6–10 years", value: "6_10_years" },
      { label: "More than 10 years", value: "more_than_10_years" },
      { label: "I don’t know", value: "dont_know" },
    ],
    multipleSelection: false,
    mandatory: true,
  },
  {
    id: "question3",
    text: "How do you currently manage your diabetes?",
    options: [
      { label: "Oral medications", value: "oral_medications" },
      { label: "Insulin injections", value: "insulin_injections" },
      {
        label: "Both oral medications and insulin",
        value: "both_oral_and_insulin",
      },
      { label: "Diet and exercise only", value: "diet_and_exercise" },
    ],
    multipleSelection: false,
    mandatory: true,
  },
  {
    id: "question4",
    text: "How often do you check your blood sugar levels?",
    options: [
      { label: "Multiple times daily", value: "multiple_daily" },
      { label: "Once daily", value: "once_daily" },
      { label: "A few times per week", value: "few_times_week" },
      { label: "Rarely", value: "rarely" },
      { label: "Never", value: "never" },
    ],
    multipleSelection: false,
    mandatory: true,
  },
  {
    id: "question5",
    text: "How would you describe your usual diet?",
    options: [
      {
        label: "Well-balanced with controlled carbohydrates",
        value: "well_balanced",
      },
      { label: "High in carbohydrates", value: "high_carbs" },
      {
        label: "High in processed or sugary foods",
        value: "high_processed_foods",
      },
    ],
    multipleSelection: false,
    mandatory: true,
  },
  {
    id: "question6",
    text: "Are you aware of your most recent HbA1c level?",
    options: [
      { label: "Below 7%", value: "below_7" },
      { label: "7–8%", value: "7_8" },
      { label: "Above 8%", value: "above_8" },
      { label: "I don’t know", value: "dont_know" },
    ],
    multipleSelection: false,
    mandatory: true,
  },
  {
    id: "question7",
    text: "How confident are you in managing your diabetes?",
    options: [
      { label: "Very confident", value: "very_confident" },
      { label: "Somewhat confident", value: "somewhat_confident" },
      { label: "Not confident", value: "not_confident" },
    ],
    multipleSelection: false,
    mandatory: true,
  },
  {
    id: "question8",
    text: "Have you experienced any of the following symptoms recently?",
    options: [
      { label: "Frequent urination", value: "frequent_urination" },
      { label: "Unusual thirst", value: "unusual_thirst" },
      { label: "Fatigue", value: "fatigue" },
      {
        label: "Tingling or numbness in hands/feet",
        value: "tingling_numbness",
      },
      { label: "Blurred vision", value: "blurred_vision" },
    ],
    multipleSelection: true,
    mandatory: false,
  },
];

const ProgramsForm: React.FC = () => {
  const [responses, setResponses] = useState<Responses>({
    ...JSON.parse(sessionStorage.getItem("programquestionsData") || "{}"),
    Symptoms: [],
  });
  const navigate = useNavigate();

  const handleResponseChange = (question: string, value: string | string[]) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [question]: value,
    }));
  };

  const handleSubmit = async () => {
    const {
      question1,
      question2,
      question3,
      question4,
      question5,
      question6,
      question7,
      question8,
    } = responses;

    if (
      (question1 === "type1" || question1 === "type2") &&
      (question2 === "1_5_years" || question2 === "6_10_years") &&
      (question3 === "insulin_injections" ||
        question3 === "both_oral_and_insulin") &&
      (question4 === "multiple_daily" || question4 === "once_daily") &&
      (question5 === "high_carbs" || question5 === "high_processed_foods") &&
      question6 === "above_8" &&
      (question7 === "somewhat_confident" || question7 === "not_confident") &&
      (question8.includes("frequent_urination") ||
        question8.includes("unusual_thirst") ||
        question8.includes("fatigue"))
    ) {
      sessionStorage.setItem("program", "Intensive Glycemic Control Plan");
    } else if (
      (question1 === "type1" ||
        question1 === "type2" ||
        question1 === "gestational") &&
      (question2 === "more_than_10_years" || question2 === "6_10_years") &&
      (question3 === "oral_medications" ||
        question3 === "insulin_injections" ||
        question3 === "both_oral_and_insulin") &&
      (question4 === "few_times_week" || question4 === "once_daily") &&
      (question5 === "high_carbs" || question5 === "high_processed_foods") &&
      (question6 === "7_8" || question6 === "above_8") &&
      (question7 === "somewhat_confident" || question7 === "not_confident") &&
      (question8.includes("blurred_vision") ||
        question8.includes("tingling_numbness"))
    ) {
      sessionStorage.setItem("program", "Advanced Diabetes Care Plan");
    } else if (
      question1 === "prediabetes" &&
      question2 === "dont_know" &&
      question3 === "diet_and_exercise" &&
      (question4 === "rarely" || question4 === "few_times_week") &&
      (question5 === "high_carbs" || question5 === "high_processed_foods") &&
      (question6 === "below_7" || question6 === "7_8") &&
      (question7 === "somewhat_confident" || question7 === "not_confident") &&
      (question8.includes("fatigue") || question8.includes("none"))
    ) {
      sessionStorage.setItem("program", "Pre-Diabetes Reversal Program");
    } else {
      sessionStorage.setItem("program", "Digital Diabetes Management Plan");
    }

    navigate("/programs/success");
  };

  const [step, setStep] = useState(0);
  const currentquestion = Programquestions[step];

  return (
    <div
      style={{
        backgroundImage: `url(32.png)`,
      }}
      className=" pt-10 bg-no-repeat bg-cover bg-center py-10 min-h-screen max-lg:w-full  max-lg:px-5 m-auto">
      <form action="" className=" w-1/3 max-lg:w-3/5 max-sm:w-full m-auto">
        <img src={logo} alt="logo" className="mx-auto py-5 w-40" />
        <h2 className="text-2xl text-center text-[#314DD3] mb-10">
          Diabetes Symptom Assessment
        </h2>
        <div className="mb-10 space-y-2 w-full ">
          <h3 className="text-base uppercase  text-gray-500 font-semibold">
            Step {step + 1}
          </h3>
          <div className="flex w-full space-x-3">
            {Array.from({ length: Programquestions.length }, (_, index) => (
              <span
                key={index}
                className={`flex-1 h-2 rounded-full ${
                  step > index
                    ? "bg-primary-900"
                    : step === index
                    ? "bg-[#6993FF]"
                    : "bg-primary-900/10"
                }`}></span>
            ))}
          </div>
        </div>
        {currentquestion && (
          <Questionnaire
            question={currentquestion}
            response={responses[currentquestion.id]}
            onChange={handleResponseChange}
            stepState={[step, setStep]}
            handleSubmit={handleSubmit}
            key={currentquestion.id}
          />
        )}
      </form>
    </div>
  );
};

const Questionnaire: React.FC<questionnaireProps> = ({
  question,
  response,
  onChange,
  stepState: [step, setStep],
  handleSubmit,
}) => {
  const [error, setError] = useState(false);

  const handleInputChange = (optionValue: string) => {
    if (question.multipleSelection) {
      const currentResponse = Array.isArray(response) ? response : [];
      const isSelected = currentResponse.includes(optionValue);
      const updatedResponse = isSelected
        ? currentResponse.filter((value) => value !== optionValue)
        : [...currentResponse, optionValue];
      onChange(question.id, updatedResponse);
    } else {
      onChange(question.id, optionValue);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <p className={`text-[#222424] text-3xl text-center mb-1`}>
        {question.text}
        {question.mandatory && <span className="text-red-500">*</span>}
      </p>
      <p className="mx-auto">
        {error && (
          <span className="text-red-500 text-sm">Please select an option</span>
        )}
      </p>
      <p className="mx-auto text-grey-500 text-sm">
        {question.multipleSelection ? "Select all that apply" : ""}
      </p>
      {question.multipleSelection ? (
        <div className="flex flex-col gap-2">
          {question.options.map((option) => (
            <label
              key={option.value}
              className={`w-full p-4 rounded-md cursor-pointer ${
                Array.isArray(response) && response.includes(option.value)
                  ? "bg-primary-900 text-white"
                  : "bg-white border border-[#6993FF]"
              }`}>
              <input
                type="checkbox"
                name={question.id}
                value={option.value}
                checked={
                  Array.isArray(response) && response.includes(option.value)
                }
                onChange={() => handleInputChange(option.value)}
                className="hidden"
              />
              {option.label}
            </label>
          ))}
        </div>
      ) : (
        question.options.map((option) => (
          <label
            key={option.value}
            className={`w-full p-4 rounded-md cursor-pointer ${
              response === option.value
                ? "bg-primary-900 text-white"
                : "bg-white border border-[#6993FF]"
            }`}>
            <input
              type="radio"
              name={question.id}
              value={option.value}
              checked={response === option.value}
              onChange={() => onChange(question.id, option.value)}
              className="hidden"
            />
            {option.label}
          </label>
        ))
      )}

      <div className="flex justify-center gap-4 mt-10">
        {step > 0 && (
          <button
            type="button"
            onClick={() => {
              setError(false);
              setStep((prev: number) => Math.max(prev - 1, 0));
            }}
            className="px-12 py-3 rounded border border-[#6993FF] text-primary-900">
            Back
          </button>
        )}
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            const isResponseEmpty =
              question.multipleSelection && Array.isArray(response)
                ? response.length === 0
                : !response;

            if (question.mandatory && isResponseEmpty) {
              setError(true);
            } else {
              setError(false);
              if (step === Programquestions.length - 1) {
                handleSubmit();
              } else {
                setStep((prevStep) => prevStep + 1);
              }
            }
          }}
          className="px-12 py-3 rounded bg-[#222424] text-white">
          {step === Programquestions.length - 1 ? "Submit" : "Next"}
        </button>
      </div>
    </div>
  );
};

export default ProgramsForm;
