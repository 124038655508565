import React from "react";
import { useNavigate } from "react-router-dom";
import { useConsumerStore } from "../../../store";
import logo from "./../../../assets/images/logo.png";

const Header: React.FC = () => {
  const navigate = useNavigate();

  const [consumer] = useConsumerStore((state) => [state.consumer]);

  return (
    <div className="bg-white shadow h-14 flex items-center justify-between px-32 max-lg:px-4">
      <div className="">
        <img src={logo} alt="logo" className="h-10" />
      </div>
      {consumer && (
        <button
          className="w-max border bg-white px-6 py-2 text-primary-800 rounded "
          onClick={() => navigate("/logout")}>
          Exit
        </button>
      )}
    </div>
  );
};

export default Header;
